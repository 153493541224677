<div class="main-container">
    <div class="flex-container">
    <div class="icon-div">
        <mat-icon class="icon-postion" >error_outline</mat-icon>
    </div>
    <div>
        <h1>Oops!</h1>
        <p>
            Looks like you are trying to use an expired or Invalid link. Please use the link in your email to access the platform.
        </p>
    </div>
</div>
</div>