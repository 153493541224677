import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ls from 'localstorage-slim';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-user-type',
  templateUrl: './check-user-type.component.html',
  styleUrls: ['./check-user-type.component.scss']
})
export class CheckUserTypeComponent implements OnInit {
  title: any;
  constructor(private _router: Router) { }

  ngOnInit(): void {

    const url = window.location.href.split('/?t');

    if (url.length > 0) {

      if (url.length === 1) {

        window.location.href = environment.externalPortalUrl;

      }

      else {

        const codeUrl = url[1].split('=');

        const paramsId = codeUrl[1];

        ls.set('paramsId', paramsId, { encrypt: true });

        this._router.navigate(['candidate']);

      }
}
  }
}
