import { Injectable } from '@angular/core';
import { ApiMethodService } from './api-method.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import ls from 'localstorage-slim';


@Injectable({
  providedIn: 'root'
})
export class RefCandidateService {
  recuriterId:any;
  constructor(private _apiService: ApiMethodService,
    private spinner: NgxSpinnerService) {

  }
  showLoader() {
    this.spinner.show();
  }

  hideLoader() {
    this.spinner.hide();
  }
  getHeaders() {
     this.recuriterId = ls.get('paramsId', { decrypt: true })
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey,
      "subscriptionId": this.recuriterId
    }
    )
    return headers;
  }
  
  mastersAPICandidate(type: any, collectionName: any, body: any) {
    const headers = this.getHeaders();
    return this._apiService.postRequest(`${environment.referecandidateapiurl}${type}/${collectionName}?source=${environment.source}`, body,headers);
  }

  mastersAPIPUTCandidate(type: any, collectionName: any, body: any) {
    const headers = this.getHeaders();
    return this._apiService.putRequest(`${environment.referecandidateapiurl}${type}/${collectionName}?source=${environment.source}`, body,headers);
  }

  mastersGetAPICandidate(collectionName: any, id: any) {
    const headers = this.getHeaders();
    return this._apiService.getRequest(`${environment.referecandidateapiurl}${collectionName}/${id}?source=${environment.source}`, headers);
  }

}
