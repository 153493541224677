export const environment = {
  production: true,
  referecandidateapiurl: 'https://apis.globalhuntindia.com/run/',
  source: 'RecuriterRefCheck',
  externalPortalUrl: 'https://jobs.globalhuntindia.com',
  redirectUrl: 'https://candidate.globalhuntindia.com/',
  domain: ".globalhuntindia.com",
  apiKey: 'uvqWWWHjJP4jFDecL1UD27sx3TMEufmi7lTn8JHu'
};

