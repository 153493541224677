<div class="main-container">

<mat-card class="cardsheet">

  <strong style="font-size: 20px;">Request new access link </strong><br><br>

  <p>The Link you've tried to access has expired. If needed, please request a new link below and we'll send it to the

    registered email address.

  </p>

  <div class="button" style="background-color: dodgerblue;color: white;padding-right: 132px;">

    <button mat-icon-button (click)="generateKey()">Request New Access Link.</button>



  </div>

</mat-card>

</div>