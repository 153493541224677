import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ls from 'localstorage-slim';
import { RefCandidateService } from '../service/ref-candidate.service';

@Component({
  selector: 'app-request-link',
  templateUrl: './request-link.component.html',
  styleUrls: ['./request-link.component.scss']
})
export class RequestLinkComponent implements OnInit {

  constructor( private _refCandidate: RefCandidateService,private router: Router) { }

  ngOnInit(): void {
  }

  async generateKey() {
    await this._refCandidate.mastersGetAPICandidate('renewRequest',ls.get('paramsId', { decrypt: true })).then((res: any) => {
      if (res!=undefined && res.data.message === 'New Request Created') {
        alert('New Link has Been Send Sucessfully. Please Check Your Email');
      }else{
        this.router.navigate(['invalid-link']);
      }
    });
  }

}
